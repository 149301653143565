import React, { useEffect } from "react"
import authStore from "../stores/auth"
import loadingSpinner from "../imgs/loading-spinner.svg"

export default function ConfirmEmail() {
  useEffect(() => {
    authStore.confirmEmail()
  }, [])

  const { error } = authStore.state
  return (
    <div style={{ textAlign: "center" }}>
      {error ? <h4>{error}</h4> : <img src={loadingSpinner} alt="spinner" />}
    </div>
  )
}
